// eslint-disable-next-line
import { graphql } from 'gatsby'
import CategoryList from '../components/category'

export default CategoryList

export const pageQuery = graphql`
  query ListPostsByCategory($category: String!, $language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        original: { frontmatter: { category: { eq: $category } } }
        frontmatter: { lang: { eq: $language } }
      }
    ) {
      edges {
        node {
          id
          excerpt
          url
          fileAbsolutePath
          frontmatter {
            title
          }
          original {
            frontmatter {
              column
              image {
                childImageSharp {
                  fluid(maxWidth: 256, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              date(formatString: "LL")
            }
          }
        }
      }
    }
  }
`
